$(".expand_button").on("click", function() {
    goToByScroll($(this).closest(".expandable_row"), 10, 0.5);
    TweenMax.set(".hidden_content_wrapper", { 
        display: "none",
        padding: "0px"
    });
    TweenMax.set($(this).closest(".expandable_row").next(), {
        display: "block",
        padding: "10px 0"
    });
});